import request from "@/utils/request";

export function getAllBroadcasts(page = 1)
{
  return request(
    {
      url   : `live-broadcasts?page=${page}`,
      method: "get"
    }
  )
}

export function createBroadcast(data) {
  return request(
    {
      url: 'live-broadcasts',
      method: 'post',
      data
    }
  )
}
