<template>
  <div class="container-fluid mt-3">
    <Breadcrumb :current_page="` جميع البثوث (${total})` "/>

    <el-card v-if="!loading">
      <table v-if="broadcasts.length" class="table text-center custom-border dashboard-table table-row-dashed h-100">
        <thead>
        <tr class="fw-bolder text-muted">
          <th class="custom-width">الترتيب</th>
          <th class="text-end">عنوان البث</th>
          <th>التاريخ</th>
          <th>الأدوات</th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="broadcast in broadcasts" :key="broadcast.id">
          <td>{{ broadcast.id }}</td>
          <td>{{ broadcast.title }}</td>
          <td>{{ broadcast.created_at }}</td>
          <td class="control-btns gap-2 d-flex justify-content-center">
            <el-tooltip class="item" effect="dark" content="تعديل البث" placement="top">
              <el-button @click="editBroadcast(broadcast.id)" class="py-1 px-2 btn btn-outline-primary">
                <i class="far fa-edit"/>
              </el-button>
            </el-tooltip>

            <el-popconfirm
                hide-icon
                confirmButtonType="danger"
                cancelButtonType="default"
                confirmButtonText="نعم"
                cancelButtonText="لا"
                iconColor="red"
                @confirm="confirmDelete(broadcast.id)"
                title="هل أنت متأكد أنك تريد حذف هذا الوسم؟">
              <template #reference>
                <el-button title="حذف البث" class="py-1 px-2 btn btn-outline-danger">
                  <i class="far fa-trash-alt"/>
                </el-button>
              </template>
            </el-popconfirm>

          </td>
        </tr>
        </tbody>
      </table>

      <p v-else>
        لا يوجد بيانات
      </p>
    </el-card>

    <Loader v-else/>
  </div>
</template>


<script>
import Loader from "@/components/Loader";
import Breadcrumb from "@/components/Breadcrumb";
import {getAllBroadcasts} from "@/services/live_broadcat";

export default {
  name      : "live_broadcasts",
  components: {
    Loader,
    Breadcrumb
  },
  data()
  {
    return {
      loading          : false,
      broadcasts       : [],
      page             : 1,
      total            : 0,
      per_page         : 1,
      paginationOptions: {
        chunk: 5,
        theme: "bootstrap4",
        //hideCount: true
      }

    }
  },
  methods: {
    fetchAllBroadcasts()
    {
      this.loading = true
      getAllBroadcasts(this.page)
          .then(res => {
            this.loading    = false;
            this.broadcasts = res.data.data
            this.total      = res.data.total
            this.per_page   = res.data.per_page
          })
          .catch(() => {
            this.loading = false
          })
    },
    editBroadcast(id)
    {
      this.$router.push(`/live-broadcast/${id}`)
    },
    confirmDelete(id)
    {

    }

  }
}
</script>

<style lang="scss">
table {
  table-layout: fixed;
}

.custom-width {
  width: 150px;
}

.el-popconfirm__action {
  display: flex;
  gap: .5rem;
  flex-direction: row-reverse;
  justify-content: center;

  .el-button + .el-button {
    margin-left: 0;
  }

  button {
    min-width: 50px;
  }
}

</style>
